@import '../../../../../public/assets/scss/colors';

.provider-card {
    &-wrapper {
        width: 100%;
        padding: 16px;
        background: $content-background-light;
        border-radius: 2px;
    }
}
