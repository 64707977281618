@import '../../public/assets/scss/colors.scss';
@import '../../public/assets/scss/media-query.scss';

$max-wdith-content: 1200px;
$header-height: 72px;

.max-content-width {
    max-width: $max-wdith-content !important;
    margin-left: auto;
    margin-right: auto;
}

.landing {
    &-wrapper {
        font-family: 'Baloo Tammudu 2', sans-serif !important;
        background-color: white !important;
        position: relative;

        button {
            width: unset !important;
            padding: 12px 16px;
            font-size: 16px;

            span {
                position: relative;
                top: 3px;
            }
        }
    }
}

.header {
    &-wrapper {
        height: $header-height;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
    }
    &-inner {
        @extend .max-content-width;

        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .app-logo {
            min-height: 40px;
            max-height: 40px;
            margin: 0;
        }
    }
}

.hero {
    &-wrapper {
        background: rgb(228, 241, 253);
        background: linear-gradient(
            45deg,
            rgba(228, 241, 253, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 1) 100%
        );
    }
    &-inner {
        @extend .max-content-width;

        padding: calc(150px + #{$header-height}) 24px 150px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        overflow: hidden;

        @include device-md {
            flex-direction: column;
            padding: calc(80px + #{$header-height}) 24px 370px 24px !important;

            .hero-image {
                margin-top: 100px;
                width: 90% !important;
                top: 45% !important;
                left: 15%;
            }

            .hero-content {
                width: 100%;
                text-align: center;
            }
        }

        .hero-image {
            width: 40%;
            height: auto;
            position: absolute;
            top: 20%;
            right: 5%;
        }
    }
    &-content {
        width: 40%;
    }
}

.howitworks {
    &-inner {
        @extend .max-content-width;

        padding: 80px 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include device-md {
            .row {
                margin-bottom: 80px;
            }
        }

        .section-line {
            width: 30%;
            margin: 0 auto;
        }

        .illustration {
            width: 100%;
            height: auto;
        }
    }
}

.features {
    &-inner {
        @extend .max-content-width;

        padding: 80px 24px;
    }
    &-item {
        margin-bottom: 32px;
        padding: 50px;
        background: white;
        border-radius: 5px;
        border: 1px solid $border-color;
        display: flex;

        @include device-sm {
            padding: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon-wrapper {
                margin-bottom: 24px;
            }

            .section-title {
                font-size: 26px !important;
                line-height: 30px;
            }
        }

        .icon {
            &-wrapper {
                min-width: 80px;
                max-width: 80px;
                min-height: 65px;
                max-height: 65px;
                position: relative;
                display: flex;
                justify-content: center;

                .material-icons {
                    font-size: 45px;
                    position: relative;
                    z-index: 1;
                }
            }
            &-bg {
                width: 100%;
                min-height: 50px;
                max-height: 50px;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                background: $border-color;
                border-radius: 100%;
                z-index: 0;
            }
        }
    }
}

.footer {
    &-inner {
        @extend .max-content-width;

        padding: 80px 16px;

        .logo {
            width: 110px;
            height: auto;
            margin: 0 auto 100px auto;
            display: block;
        }

        a {
            color: inherit !important;
            font-size: inherit !important;
        }
    }
}

.sections-wrapper {
    background: rgb(241, 248, 255);
    background: linear-gradient(
        0deg,
        rgba(241, 248, 255, 1) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

.section-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.section-subtitle {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 600;
    color: $text-secondary;
}
