@import '../../../../../public/assets/scss/colors.scss';
@import '../../../../../public/assets/scss/media-query.scss';

.messages {
    &-wrapper {
        padding: 32px 16px;

        @include device-md {
            padding-top: 92px;
            padding-bottom: 72px;
        }

        .message {
            &-thread {
                &-wrapper {
                    margin-bottom: 8px;
                    background: white;
                    border: 1px solid $border-color;

                    &.closed {
                        background-color: $hint;
                    }
                }

                &-header {
                    padding: 16px 32px;
                    align-items: center;
                    justify-content: space-between;

                    @include device-md {
                        align-items: unset;
                    }

                    .header-icon {
                        width: auto;
                        height: auto;
                        background-color: $tag-background;
                        border-radius: 50%;

                        i {
                            vertical-align: middle;
                            padding: 8px 0;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &.collapsed,
                    &:hover {
                        cursor: pointer;
                        background-color: $content-background-light;
                    }
                }
            }

            &-item {
                &-wrapper {
                }

                &-inner {
                    padding: 24px 32px;

                    .acknowledgement {
                        &-wrapper {
                            padding: 16px 24px;
                            border-radius: 5px;
                            border-top: 4px solid $avs-green;
                            background-color: rgba($avs-green, 0.07);
                        }
                    }

                    .reply {
                        &-wrapper {
                            padding: 16px 24px;
                            border-radius: 5px;
                            border-top: 4px solid $avs-blue;
                            background-color: rgba($avs-blue, 0.04);

                            textarea {
                                width: 100%;
                                min-height: 100px;
                                margin-top: 16px;
                                padding: 8px 12px;
                                border: 0;
                                background: transparent;
                            }
                        }
                    }

                    .file-attachment {
                        &-wrapper {
                            margin: 0 8px 8px 0;
                        }

                        &-inner {
                            padding: 4px 8px;
                            display: inline-flex;
                            align-items: center;
                            border-radius: 5px;
                            background-color: rgba($avs-blue, 0.07);

                            &:hover {
                                cursor: pointer;
                                background-color: rgba($avs-blue, 0.12);
                            }
                        }
                    }

                    .file-uploader {
                        &-wrapper {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            position: relative;

                            &:hover {
                                cursor: pointer;
                            }

                            input[type='file'] {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                opacity: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .empty-state {
            &-img {
                width: 200px;
                height: auto;
            }
        }
    }
}
