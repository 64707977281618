@import '../../../public/assets/scss/colors';

$height: 80px;

.top-bar {
    &-wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

        .app-logo {
            margin: 20px;
        }

        .navigation {
            &-wrapper {
                height: 100%;
                display: flex;
            }

            &-item {
                height: 100%;
                padding: 32px;
                display: flex;
                align-items: center;
                color: #bbbbbb;
                overflow: hidden;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;

                &:hover,
                &.active {
                    color: $avs-blue;
                    cursor: pointer;
                    position: relative;

                    &:after {
                        content: '';
                        height: 6px;
                        width: 100%;
                        position: absolute;
                        right: 0;
                        bottom: -3px;
                        left: 0;
                        background: $avs-blue;
                        border-radius: 100px;
                    }
                }
            }
        }

        .patient-block {
            padding: 8px 12px;
            background: $content-background-light;
            border-radius: 2px;
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        .logout-button {
            &-wrapper {
                height: 100%;
                padding: 28px;
                background: $content-background-light;
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;

                    .material-icons {
                        color: $avs-blue;
                    }
                }
            }
        }
    }
}
