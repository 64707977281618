@import '../../../../../public/assets/scss/colors.scss';

.manage-members {
    &-wrapper {
        .member {
            &-wrapper {
                margin-bottom: 8px;
                padding: 12px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: white;
                border: 1px solid $border-color;
                border-radius: 5;
            }
        }
    }
}
