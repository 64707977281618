@import '../../../../../public/assets/scss/colors';

.appointment-card {
    &-wrapper {
        margin-bottom: 16px;
        padding: 32px 24px 24px 24px;
        background: white;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

        &:last-child {
            margin-bottom: 0;
        }
    }
}
