@import '../../../public/assets/scss/colors';
@import '../../../public/assets/scss/media-query';

$max-width: 400px;
$min-height: 400px;

.auth-card {
    &-wrapper {
        width: 100%;
        max-width: $max-width;
        min-height: $min-height;
        display: inline-block;
        background: white;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        overflow-y: auto;

        @include device-sm {
            height: 100vh;
            width: 100vw;
        }
    }

    &-inner {
        padding: 32px 32px 120px 32px;

        .form-step-indicator {
            min-width: 8px;
            min-height: 8px;
            max-width: 8px;
            max-height: 8px;
            margin-right: 8px;
            border-radius: 100%;
            background: white;
            opacity: 0.3;

            &.active {
                opacity: 1;
            }
        }

        .react-datepicker-wrapper {
            width: 100%;
        }
    }

    &-title {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
    }

    &-subtitle {
        margin-bottom: 40px;
        font-size: 14px;
        color: $text-secondary;
    }

    &-next-button {
        position: relative;

        input[type='submit'] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
    }
}
