@import '../../../../public/assets/scss/colors';
@import '../../../../public/assets/scss/media-query';

$max-width: 500px;

.member-details-modal {
    &-wrapper {
        max-width: $max-width;

        @include device-md {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0;

            .modal {
                &-content {
                    height: 100%;
                }
                &-body {
                    max-height: 100vh !important;
                }
            }
        }

        .modal {
            &-body {
                max-height: 70vh;
                overflow-y: auto;
            }
        }
    }

    &-header {
        font-size: 18px;
    }

    &-body {
        flex: 1;
    }

    &-form {
        width: 75%;
    }
}
