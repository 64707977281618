// Application colours
$text: #333333;
$text-secondary: #757575;
$hint: #9e9e9e;
$content-background: #f5f5f5;
$content-background-light: #fafafa;
$app-background: #f3f6f9;
$form-background: #fafafa;
$border-color: #e8e8e8;
$avs-blue: #318de3;
$avs-red: #c7474a;
$avs-green: #82c64e;
$avs-yellow: #ffc836;
$avs-orange: #ff9c2e;
$avs-purple: #7f39fb;
$avs-nav: #344051;
$avs-nav-dark: #27364c;
$tag-background: #e3e3e3;
