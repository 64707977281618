@import '../../../public/assets/scss/colors';

.toggle {
    &-wrapper {
        width: 28px;
        min-width: 28px;
        height: 16px;
        min-height: 16px;
        position: relative;
        display: inline-block;

        &.active {
            .toggle {
                &-inner {
                    background: $avs-green;
                }

                &-knob {
                    right: -1px;
                    border: 1px solid $avs-green;
                }
            }
        }

        &.inactive {
            .toggle {
                &-inner {
                    background: $hint;
                }

                &-knob {
                    right: 11px;
                    border: 1px solid $hint;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-inner {
        width: 100%;
        height: 100%;
        border-radius: 300px;
        -webkit-transition: background-color 0.5s ease;
        transition: background-color 0.5s ease;
    }

    &-knob {
        width: 18px;
        min-width: 18px;
        height: 18px;
        min-height: 18px;
        border-radius: 50%;
        background-color: white;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        -webkit-transition: right 0.1s ease-out;
        transition: right 0.1s ease-out;
        position: absolute;
        top: -1px;
        z-index: 1;
    }
}
