@import '../../../../../public/assets/scss/colors.scss';
@import '../../../../../public/assets/scss/media-query.scss';

$menu-option-width: 200px;

.account {
    &-wrapper {
        padding: 32px 16px;
        display: flex;
        align-items: flex-start;

        @include device-md {
            padding-top: 92px;
            padding-bottom: 72px;
            flex-direction: column;
        }

        .menu {
            &-options {
                &-wrapper {
                    margin-right: 32px;
                }

                &-item {
                    min-width: $menu-option-width;
                    margin-bottom: 8px;
                    padding: 12px 16px;
                    background: white;
                    font-weight: 500;
                    border: 1px solid $border-color;

                    &.active,
                    &:hover {
                        cursor: pointer;
                        color: $avs-blue;
                        position: relative;
                        overflow: hidden;

                        &::before {
                            width: 6px;
                            content: '';
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: -3px;
                            border-radius: 100px;
                            background: $avs-blue;
                        }
                    }
                }
            }

            &-dropdown {
                .dropdown {
                    &-toggle {
                        margin-bottom: 16px;
                        padding: 8px 12px;
                        font-weight: 500;
                        background: white;
                    }

                    &-item {
                        font-size: 14px;
                        font-weight: 500;
                        padding: 12px 16px;
                    }
                }
            }

            &-panel {
                width: 100%;
                flex: 1 1 auto;
                padding: 24px;
                background: white;
                border: 1px solid $border-color;
            }
        }
    }
}
