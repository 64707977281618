@import '../../../public/assets/scss/colors.scss';

.icon-button {
    &-wrapper {
        padding: 6px;
        border-radius: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &.hover {
            &:hover {
                cursor: pointer;
                background: $border-color;

                .material-icons {
                    transform: scale(1.05, 1.05);
                }
            }
        }
    }
}
