@import '../../../public/assets/scss/colors.scss';
@import '../../../public/assets/scss/media-query.scss';

.appointments {
    &-wrapper {
        padding: 32px 16px 70px 16px;

        @include device-sm {
            padding-top: calc(59px + 24px);
        }

        .empty-state {
            &-img {
                margin: 50px auto 8px auto;
                width: 200px;
                height: auto;
                display: block;
            }
        }
    }
}
