@import '../../../public/assets/scss/colors';

.confirmation-popper {
    &-wrapper {
        border: 1px solid $avs-red;
        background: #f7eeef;
        color: $avs-red;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

        .arrow {
            &::before {
                border-bottom-color: $avs-red;
            }
            &::after {
                border-bottom-color: #f7eeef;
            }
        }
    }
}
