@import '../../../public/assets/scss/colors';
@import '../../../public/assets/scss/media-query';

$max-width: 500px;

.lean-more-modal {
    &-wrapper {
        max-width: $max-width;

        @include device-md {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0;

            .modal {
                &-content {
                    height: 100%;
                }
            }
        }
    }

    &-header {
        font-size: 18px;
    }

    &-body {
        flex: 1;

        .member {
            &-wrapper {
                margin-bottom: 8px;
                padding: 12px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: white;
                border: 1px solid $border-color;
                border-radius: 2;
            }
        }
    }

    &-form {
        width: 75%;
    }
}
