@import '../../../public/assets/scss/colors';

.toasts {
    &-wrapper {
        position: fixed;
        right: 16px;
        bottom: calc(38px + 16px);
        z-index: 9999;
    }
}

.toast {
    &-header {
        padding: 10px 16px;
        border: 0;
    }

    button {
        height: 20px;

        &.close {
            width: auto;
            margin: 0 0 0 16px;
            display: flex;
            align-items: center;
            font-weight: normal;
        }
    }

    &.success {
        border-color: $avs-green;

        .toast-header {
            background: rgba($avs-green, 0.8);
            color: white;

            button {
                color: white;
            }
        }
    }

    &.warning {
        border-color: $avs-orange;

        .toast-header {
            background: rgba($avs-orange, 0.8);
            color: white;

            button {
                color: white;
            }
        }
    }

    &.error {
        border-color: $avs-red;

        .toast-header {
            background: rgba($avs-red, 0.8);
            color: white;

            button {
                color: white;
            }
        }
    }

    &.info {
        border-color: $border-color;

        .toast-header {
            background: rgba($border-color, 0.8);
            color: $text-secondary;

            button {
                color: $text-secondary;
            }
        }
    }
}
