.app-bar {
    &-wrapper {
        min-height: 59px;
        padding: 16px 20px;
        font-size: 18px;
        font-weight: 500;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .content-center {
            width: 200px;
            height: 28px;
            margin: auto;
            text-align: center;
            position: absolute;
            top: calc(50% - 28px / 2);
            left: calc(50% - 200px / 2);
        }
    }
}
