@import '../../../../../public/assets/scss/colors';

.book-appointment {
    &-wrapper {
        .menu {
            &-wrapper {
                min-width: 175px;
                margin-right: 24px;
            }

            &-item {
                margin-bottom: 16px;
                padding: 8px 12px;

                &.active,
                &:hover {
                    cursor: pointer;
                    background: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .panel {
            &-wrapper {
                display: flex;
                flex-direction: column;
            }

            &-inner {
                padding: 32px 32px 24px 32px;
                margin-bottom: 16px;
                background: white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

                .search-provider {
                    &-wrapper {
                        padding-left: 4px;
                        display: flex;
                        align-items: center;
                        border-radius: 2px;
                        border: 1px solid $border-color;

                        .rbt {
                            width: 100%;

                            input {
                                border: 0;
                            }

                            .dropdown {
                                &-item {
                                    background: $content-background-light;

                                    &:nth-child(2n) {
                                        background: white;

                                        .provider-card-wrapper {
                                            background: white;
                                        }

                                        &:hover {
                                            background: white !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .react-datepicker {
                    &-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-next-button {
        position: relative;

        input[type='submit'] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
    }
}
