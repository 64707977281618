@import '../public/assets/scss/colors';
@import '../node_modules/react-datepicker/dist/react-datepicker.min.css';

$max-content-width: 1000px;

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    background: $app-background;
    color: $text;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    outline: none !important;
}

a {
    color: $avs-blue;
    font-size: 13px;
    font-weight: 500;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

input[type='submit'],
button {
    width: 100%;
    padding: 8px 12px;
    border: 0;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 2px;

    &.primary {
        background: $avs-blue;
        color: white;
    }
}

[readOnly] {
    &.readonly-input {
        background: initial !important;

        &:hover {
            cursor: pointer;
        }
    }

    &.readonly-dropdown {
        background: initial !important;
        background-image: linear-gradient(45deg, transparent 50%, $hint 50%),
            linear-gradient(135deg, $hint 50%, transparent 50%),
            linear-gradient(to right, white, white) !important;
        background-position: calc(100% - 15px) 15px, calc(100% - 11px) 15px,
            100% 0 !important;
        background-size: 4px 4px, 4px 4px, 2.5em 2.5em !important;
        background-repeat: no-repeat !important;

        &:hover {
            cursor: pointer;
        }
    }
}

.div-button {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.left-16 {
    left: 16px;
}

.flex-1 {
    flex: 1 1 100%;
}

.width-40p {
    width: 40%;
}

.max-content-width {
    width: 100%;
    max-width: $max-content-width;
    margin: auto;
}

.width-unset {
    width: unset !important;
}

.width-45 {
    width: 45px;
}

.min-width-40p {
    min-width: 40%;
}

.max-width-40p {
    width: 100%;
    max-width: 40%;
}

.min-height-39 {
    min-height: 39px;
}

.flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.material-icons {
    &.md-14 {
        font-size: 14px;
    }

    &.md-16 {
        font-size: 16px;
    }

    &.md-18 {
        font-size: 18px;
    }

    &.md-20 {
        font-size: 20px;
    }
}

.color-white {
    color: white;
}

.color-blue {
    color: $avs-blue;
}

.color-green {
    color: $avs-green;
}

.color-red {
    color: $avs-red;
}

.color-orange {
    color: $avs-orange;
}

.color-text-secondary {
    color: $text-secondary;
}

.color-text-hint {
    color: $hint;
}

.background-none {
    background: none;
}

.background-content-background-light {
    background-color: $content-background-light;
}

.background-green {
    background-color: $avs-green;
}

.font-size-initial {
    font-size: initial;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.uppercase {
    text-transform: uppercase;
}

.text-transform-none {
    text-transform: none !important;
}

.whitespace-pre-line {
    white-space: pre-line;
}

.word-break-break-all {
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
}

.truncate-row-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.invalid-state {
    border: 1px solid $avs-red !important;
}

.resize-none {
    resize: none;
}

.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;
    opacity: 0.6;

    &:before {
        -webkit-animation: btn-spin 0.5s infinite linear;
        animation: btn-spin 0.5s infinite linear;
        border: 2px solid white;
        border-radius: 50%;
        border-right-color: $avs-blue;
        border-top-color: $avs-blue;
        content: '';
        height: 1.1em;
        width: 1.1em;
        position: absolute;
        left: calc(50% - (1.1em / 2));
        top: calc(50% - (1.1em / 2));
        z-index: 2;
    }

    * {
        opacity: 0;
    }
}

@keyframes btn-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

// Classes for react-bootstrap layout
.form-row > .col,
.form-row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
}

// Classes for bootstrap form
.form {
    &-control {
        height: unset;
        padding: 8px 12px;
        border-radius: 2px;
        border-color: $border-color;
        font-size: 14px;

        &[disabled],
        &[readonly] {
            background: $content-background;

            &:focus {
                background: $content-background;
            }
        }

        &:focus {
            background: white;
            border-color: $border-color;
            box-shadow: none;
        }
    }

    &-group {
        .input-group-text {
            border-color: $border-color;
            border-radius: 2px;
        }
    }

    &-label {
        font-weight: 500;
    }
}

// Classes for react-bootstrap dropdown
.dropdown {
    &.invalid-state {
        border: 1px solid $avs-red !important;
    }

    button {
        &.dropdown-toggle {
            padding: 8px 12px;
            outline: none !important;

            &:focus {
                box-shadow: none !important;
            }

            &::after {
                display: none;
            }

            &.initial {
                border-radius: 2px;
                font-size: 14px !important;
            }

            &.background-white {
                background: white !important;
            }

            &.color-initial {
                color: initial !important;
            }

            &.border-none {
                border: 0 !important;
            }
        }
    }

    &-menu {
        max-height: 250px;
        overflow-y: auto;
    }

    &-item {
        padding: 8px 12px;
        font-size: 14px;

        &:focus,
        &:hover,
        &:active {
            background-color: $content-background-light !important;
            color: initial !important;
        }

        &.active {
            background-color: white !important;
            color: $avs-blue !important;

            .material-icons {
                color: $avs-blue !important;
            }
        }
    }
}

.dropdown-div {
    padding: 8px 12px;
    border: 1px solid $border-color;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
}

// Classes for tooltip
.tooltip {
    font-size: 13px;
}

// Classes for react-datepicker
.react-datepicker {
    border: 0 !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

    &-popper {
        z-index: 9999;
    }

    &__header {
        background-color: $content-background-light !important;
        border: 0 !important;
    }

    &__day {
        &--selected {
            background-color: $avs-blue !important;
            color: white !important;
            border-radius: 2px;
        }

        &--keyboard-selected {
            background-color: rgba($avs-blue, 0.8) !important;
        }
    }

    &__month-container {
        width: 100%;
    }

    &__month-dropdown,
    &__year-dropdown,
    &__month-year-dropdown {
        max-height: 200px;
        overflow-y: auto;
        background-color: $content-background-light !important;
        border: 0 !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

        .react-datepicker__month-option,
        .react-datepicker__year-option,
        .react-datepicker__month-year-option {
            &:hover,
            &.--selected_month,
            &.--selected_year,
            &.--selected_month-year {
                background-color: $content-background !important;
                color: $avs-blue;
                font-weight: 500;
            }
        }
    }

    &__month-year-dropdown-container {
        background: #eeeeee;
        border-radius: 2px;

        .react-datepicker__month-year-read-view--down-arrow {
            margin-left: 32px;
            margin-right: 8px;
            border-width: 6px;
            top: 6px;
        }
    }

    &__time-list-item {
        &--disabled {
            display: none;
        }
    }

    &__close-icon {
        &::after {
            background: transparent;
            color: $hint;
            font-size: 18px;
        }
    }

    &__input-container {
        input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid $border-color;
            border-radius: 2px;
        }
    }
}

// Classes for modal
.modal {
    &-header {
        padding: 24px 0;
        border-bottom: 0;
        position: relative;
        display: flex;
        justify-content: center;
    }

    &-title {
        font-size: 16px;
        font-weight: 500;
    }

    &-body {
        padding: 8px 24px;
    }

    &-footer {
        padding: 16px;
        border-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
