@import '../../../public/assets/scss/colors';

.navigation-bar {
    &-wrapper {
        width: 100%;
        min-height: 54px;
        background: white;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

        .navigation {
            &-wrapper {
                width: 100%;
                min-height: 100%;
                display: flex;
            }

            &-item {
                flex: 1 1 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active,
                &:hover {
                    .material-icons {
                        color: $avs-blue;
                    }
                }
            }
        }
    }
}
