@import '../../../../public/assets/scss/colors';
@import '../../../../public/assets/scss/media-query';

$max-width: 500px;

.request-provider-modal {
    &-wrapper {
        max-width: $max-width;

        @include device-md {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0;

            .modal {
                &-content {
                    height: 100%;
                }
                &-body {
                    max-height: 100vh !important;
                }
            }
        }

        .modal {
            &-body {
                max-height: 70vh;
                overflow-y: auto;
            }
        }
    }

    &-header {
        font-size: 18px;
    }

    &-body {
        flex: 1;

        .member-wrapper {
            margin-bottom: 8px;
            display: flex;
            background: white;
            border-radius: 5px;
            border: 1px solid $border-color;

            &:hover {
                cursor: pointer;
            }

            .section {
                &-left {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    background: $content-background-light;
                }

                &-right {
                    padding: 16px 24px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &-form {
        width: 75%;
    }
}
