@import '../../../public/assets/scss/colors';

$size: 44px;

.avatar {
    &-wrapper {
        min-width: $size;
        min-height: $size;
        max-width: $size;
        max-height: $size;
        padding: 8px;
        border-radius: 100px;
        border: 2px solid white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
        background: $border-color;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
