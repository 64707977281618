@import '../../../public/assets/scss/colors';

$width: 400px;
$min-height: 400px;

.pagecard {
    &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
    }

    &-inner {
        width: $width;
        min-height: $min-height;
        padding: 32px 32px 40px 32px;
        background: white;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);

        .title {
            font-size: 30px;
            font-weight: bold;
            color: $avs-nav;
        }
        .subtitle {
            margin-top: 8px;
            color: $text-secondary;
        }
    }
}
